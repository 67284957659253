.tea-name {
}

.tea-entry {
  margin-bottom: 0.2cm;
}

.tea-menu {
  max-width: 17cm;
}

body {
  padding: 0.2cm;
}

.sensitive-indicator {
  font-weight: bold;
  padding-left: 0.5cm;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .25rem .4rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

.large-circle {
  border-radius: 50%;
  width: 1.5cm;
  height: 1.5cm;
  line-height: 1.5cm;
  font-size: 1.25cm;
  padding: 0;
  background: #fff;
  color: #000;
  text-align: center;
}

.caffeinated-brown {
  background-color: #d9b38c;
  color: #ffffff;
}

.decaf-blue {
  background-color: #66ffff;
  color: #ffffff;
}

.tea-black {
  background-color: #4d3319;
  color: #ffffff;
}

.tea-green {
  background-color: #66ff66;
  color: #ffffff;
}

.tea-black {
  background-color: #663300;
  color: #ffffff;
}

.tea-herbal {
  background-color: #ffccb3;
  color: #ffffff;
}
